import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks } from './common';
import imageCatUrl from '@hse-design/core/lib/assets/images/cat.svg?file';
import imageCommasUrl from '@hse-design/core/lib/assets/images/commas.svg?file';
import imageDoneUrl from '@hse-design/core/lib/assets/images/done.svg?file';
import imageUfoUrl from '@hse-design/core/lib/assets/images/ufo.svg?file';
import imageCrowUrl from '@hse-design/core/lib/assets/images/crow.svg?file';
import imageFailUrl from '@hse-design/core/lib/assets/images/fail.svg?file';
import imageLinkUrl from '@hse-design/core/lib/assets/images/link.svg?file';
import imageSearchUrl from '@hse-design/core/lib/assets/images/search.svg?file';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "иллюстрации"
    }}>{`Иллюстрации`}</h1>
    <ComponentLinks figma="https://www.figma.com/file/h3HKVyYAAF2Q2dMvgCZyM1/hse_assets?node-id=0%3A1" mdxType="ComponentLinks" />
    <p>{`Иллюстрации доступны в виде изображений в папке
`}<a parentName="p" {...{
        "href": "https://gitlab.com/proscom/hse-design/-/tree/master/packages/core/src/assets/images"
      }}><inlineCode parentName="a">{`lib/assets/images`}</inlineCode></a>{`
пакета `}<inlineCode parentName="p">{`@hse-design/core`}</inlineCode>{`.`}</p>
    <p>{`Не рекомендуем вставлять иллюстрации как инлайн svg, так как они могут быть достаточно объемными.
Подключайте их через `}<inlineCode parentName="p">{`<img src="..." />`}</inlineCode>{` либо как `}<inlineCode parentName="p">{`background-image: url(...)`}</inlineCode>{`.`}</p>
    <p>{`При использовании Webpack, вы можете ссылаться на файлы напрямую из пакета:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.img {
  background-image: url("~@hse-design/core/lib/assets/images/cat.svg?file");
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// Добавляем в конец query-параметр, чтобы svgr-loader не сработал на файл с расширением svg
import imageCatUrl from '@hse-design/core/lib/assets/images/cat.svg?file';

function MyComponent() {
  return <img src={imageCatUrl} />;
}
`}</code></pre>
    <p>{`Так как иллюстрации также имеют расширение svg, то загрузка иллюстраций по ссылке может конфликтовать
с импортом иконок как компонентов с помощью svgr-loader или vue-svg-loader.
В случае возникновения проблем обращайтесь к документации пакетов, соответствующих вашей конфигурации.`}</p>
    <p>{`Если вы не используете Webpack, то можете просто скопировать необходимые иллюстрации из папки
`}<inlineCode parentName="p">{`node_modules/@hse-design/core/lib/assets/images`}</inlineCode>{` в директорию публичных файлов вашего проекта.`}</p>
    <h2 {...{
      "id": "примеры-иллюстраций"
    }}>{`Примеры иллюстраций`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: -12
    }}>
  {[{
        url: imageCatUrl,
        name: 'cat.svg'
      }, {
        url: imageCommasUrl,
        name: 'commas.svg'
      }, {
        url: imageDoneUrl,
        name: 'done.svg'
      }, {
        url: imageUfoUrl,
        name: 'ufo.svg'
      }, {
        url: imageCrowUrl,
        name: 'crow.svg'
      }, {
        url: imageFailUrl,
        name: 'fail.svg'
      }, {
        url: imageLinkUrl,
        name: 'link.svg'
      }, {
        url: imageSearchUrl,
        name: 'search.svg'
      }].map((image, iImage) => <div key={iImage} style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 12
      }}>
      <div>
        <img src={image.url} style={{
            height: '128px '
          }} />
      </div>
      <p>{image.name}</p>
    </div>)}
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      